import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Button,
  useColorMode,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import RouterLink from '../../components/Utility/Routing/RouterLink';
import ImgTextModule from '../../components/elements/ImgTextModule';
import FadeInAnimation from '../../components/UX/FadeInAnimation';
import MagicText from '../../components/UX/MagicText';
import TeamTri from '../../components/TeamTri';
import TeamDuo from '../../components/TeamDuo';
import KontaktFooter from '../../components/KontaktFooter';
import ExpertenQuint from '../../components/ExpertenQuint';
const IndexPage = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode('light');
  });

  return (
    <>
      <Helmet>
        <title>KDW | Das Team</title>
        <meta
          name='description'
          content='kdw HR bietet Kunden Dienstleistungen von der Personalvermittlung, über qualifizierte Arbeitnehmerüberlassung bis hin zu Management- und Fördermittelberatung im gesamten Personalmanagementbereich. Arbeitnehmer finden bei uns langfristige Karriereoptionen genauso wie neue Herausforderungen vornehmlich im Industrie-, Gewerbe- und Officesegment.'
        />
        <link rel='canonical' href='https:/kdw-hr.de' />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Helmet>

      {/* CONTAINER MAGICTEXT */}

      <ImgTextModule
        src={'All-4.mp4'}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h='55vh'
        mediaOpacity={0.8}
        bgColor='brand.gray.100'
      >
        <Container variant='layoutContainer' mt={8}>
          <Box p={{ base: 0, lg: '8' }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.4}
              initialX={0}
              initialY={-10}
            >
              <Text
                color='white'
                fontWeight='normal'
                mx={'auto'}
                textAlign={'center'}
                fontSize={{ base: 'md', lg: 'lg' }}
              >
                Wir sind Mittler, Begleiter, Entwickler
              </Text>
              <Text
                color='brand.yellow'
                fontWeight='normal'
                mx={'auto'}
                textAlign={'center'}
                fontSize={{ base: '4xl', lg: '6xl' }}
              >
                Unser Team.
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color='brand.gray.1000'
                mb={24}
                maxW={{ base: '100%', lg: '50%' }}
                fontSize={{ base: '3xl', lg: '4xl' }}
                lineHeight={'short'}
                mx={'auto'}
                textAlign={'center'}
              >
                Neue Wege beschreitet man am Besten mit einem starken Team.
              </Text>
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>
      <TeamTri />

      <Container variant='layoutContainer' mt={8}>
        <Box p={{ base: 0, lg: '8' }}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.4}
            initialX={0}
            initialY={-10}
          >
            <Text
              color='brand.yellow'
              fontWeight='normal'
              mx={'auto'}
              textAlign={'center'}
              fontSize={{ base: '4xl', lg: '6xl' }}
            >
              Unser Team.
            </Text>
          </FadeInAnimation>
        </Box>
      </Container>
      <TeamDuo />
      <ExpertenQuint />

      <ImgTextModule
        src={'All-4.mp4'}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h='55vh'
        mediaOpacity={0.7}
        bgColor='black'
      >
        <Container variant='layoutContainer' mb={16}>
          <Box p={{ base: 0, lg: '8' }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color='white'
                fontWeight='normal'
                fontSize={{ base: '2xl', lg: '2xl' }}
              >
                Arbeitnehmer
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.4}
              initialX={0}
              initialY={-10}
            >
              <Text
                color='brand.green'
                fontWeight='normal'
                fontSize={{ base: '3xl', lg: '6xl' }}
              >
                Karriere und
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.4}
              initialX={0}
              initialY={-10}
            >
              <Text
                color='brand.yellow'
                fontWeight='normal'
                fontSize={{ base: '3xl', lg: '6xl' }}
              >
                persönliche Entwicklung.
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color='brand.gray.1000'
                mb={8}
                maxW={{ base: '100%', lg: '50%' }}
                fontSize='xl'
                lineHeight={'short'}
              >
                Arbeitnehmer finden bei uns langfristige Karriereoptionen
                genauso wie neue Herausforderungen vornehmlich im Industrie-,
                Gewerbe- und Officesegment.
              </Text>
            </FadeInAnimation>
            <FadeInAnimation threshold={0.4} duration={1} delay={0.7}>
              <RouterLink isExternal={'false'} link={'/stellen'}>
                <Button
                  mt={2}
                  px={14}
                  pt={4}
                  pb={9}
                  fontSize='md'
                  textAlign='center'
                  variant={'solid'}
                  color='white'
                  bg='brand.violet'
                >
                  Freie Stellen
                </Button>
              </RouterLink>
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>
      <KontaktFooter />
    </>
  );
};

export default IndexPage;
